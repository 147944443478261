@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import '~antd/dist/antd.css';
@import '.';

* {
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

// Global Variables
#root {
  --header-height: 3.5rem;
  --overall-padding-x: 2rem;
}

p {
  margin-bottom: 0;
}

img {
  -webkit-user-drag: none;
}

.ant-tooltip-inner {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  border-radius: 12px !important;
  padding: 8px 16px !important;
  font-size: 12px !important;
}
